.global-header {
  .layout-content {
    display: flex;

    .ant-btn {
      // padding: 5px 10px;
      // outline: none;
      // border: 0;
    }
  }

  // Dark theme specific css
  // Added considering stoptb
  &.dark {
    .left-bar {
      background-color: #31404f;
    }

    .sidebar-container {
      .sidemenu {
        .ant-menu.ant-menu-dark {
          background-color: #31404f;
        }

        .ant-menu-dark .ant-menu-inline.ant-menu-sub {
          background-color: #2b3946;
        }

        .intro {
          border-bottom: none;
        }

        .menu-item {
          .menu-collapsed {
            i {
              color: white;
            }
          }
        }
      }
    }

    .sidebar-container .sidemenu .intro .logo-wrapper small {
      color: #fff;
    }
  }

  // END OF STOPTB SPECIFIC CSS

  .left-bar {
    transition: 0.3s;

    &.hide {
      width: 0;
      display: none;
    }

    &.open {
      width: 256px;
    }

    &.close {
      width: 106px;
    }

    .sidebar-container {
      transition: 0.3s;

      &.open {
        width: 256px;
        transition: 0.1s;
      }

      &.close {
        width: 80px;
      }
    }

    @media only screen and (min-width: 800px) {
      width: 256px;
    }
  }

  &.connected {
    .right-section {
      margin-top: 0px;
      padding: 0px;
    }
  }

  .right-section {
    margin-top: 0px;
    width: 100%;
    overflow-x: scroll;
    position: relative;
    padding: 15px;

    .menu-caption {
      margin: 12px 10px;
    }

    // margin-top: 0px;
    // width: 100%;
    // overflow-x: scroll;
    // position: relative;
    // padding: 15px;
    // height: 100vh;

    .toggler {
      position: absolute;
      bottom: 3px;
      left: -1px;
      border: 1px solid #c6c6c6;

      &:hover {
        background-color: hover;
        border-color: black;
      }
    }

    @media only screen and (min-width: 768px) {
      // padding: 10px 20px;
    }
  }

  .profile-avatar {
    .profile-picture {
      border: 1px solid #e2e2e2;
      border-radius: 23px;
      box-shadow: 0 0 8px 2px #d1d1d1;
      color: #000;
      height: 40px;
      margin: 0 10px;
      object-fit: contain;
      width: 40px;
    }
  }

  .ant-tooltip .ant-tooltip-content .ant-tooltip-arrow+span {
    opacity: 1 !important;
  }

  .ant-menu.ant-menu-inline-collapsed>.ant-menu-item+span {
    opacity: 1 !important;
  }

  .ant-menu {
    .ant-menu-item {
      .ant-menu-submenu-popup {
        .ant-menu-title-content+span {
          opacity: 1 !important;
        }
      }
    }
  }

  .fa {
    padding-right: 15px;
  }


  .page-wrapper {
    // margin: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 8px 3px 8px;

    @media only screen and (max-width: 768px) {

      padding: 8px 10px 8px 14px;
    }

    .page-header-name {
      display: flex;
    }

    &.hidden {
      display: none;
    }

    .toggle-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      h4 {
        margin-bottom: 0px;
      }
    }

    .toggle-menu {
      margin-right: 10px;
      cursor: pointer;
      // padding: 10px;
      // border-radius: 4px;
      // border: 1px solid #dcdcdc;
      margin-right: 10px;

      span {
        margin-right: 10px;
      }

      &:hover {
        border-color: #c1c1c1;
      }
    }

    .show-search {
      margin-right: 10px;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        border-color: #c1c1c1;
      }
    }

    .page-menu {
      display: flex;
      align-items: center;
      gap: 15px;

      a {
        margin: 0px 5px;
      }

      .branch-selection {
        margin: 0px 10px;
      }
    }

    .header-blk {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .page-heading {
        margin: 2px 0 0 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
      }
    }

    .page-desc {
      margin-bottom: 10px;
    }
  }
}

.side-drawer-content-wrapper {

  .drawer-content {
    .ant-drawer-body {
      padding: 0px;
    }
  }
}

.sidebar-container {
  // border-right: 1px solid #f0f0f0;
  height: 100vh;
  margin-top: 0px;
  width: 256px;
  // .opened{
  //   width: 200px;
  //   // transition : 0.1s ;
  // }
  // .closed{
  //   width: 80px;
  // }

  //   .sidebar-container:hover{
  //     width: 200px;
  // }

  .menu-search {
    padding: 10px;
  }

  .user-info {
    padding: 5px 16px;
  }
}

.side-drawer-content {
  .ant-drawer-content-wrapper {
    width: 256px !important;

    .ant-drawer-content {
      .ant-drawer-body {
        padding: 0px;
      }
    }
  }
}