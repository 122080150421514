.user-authentication-section {
  min-height: 90vh;
  display: flex;
  margin: 0px 15%;
  justify-content: center;
  // align-items: center;

  .page-background {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 40vh;
    width: 100%;
    // background-color: whitesmoke;
    z-index: -1;

    background: linear-gradient(to right, #7ddafac2, #10c7ef61);

    // background: #00b4db; /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #0083b0, #00b4db); /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(
    //   to right,
    //   #0083b0,
    //   #00b4db
    // ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // background: rgb(131, 58, 180);
    // background: -moz-linear-gradient(
    //   90deg,
    //   rgba(131, 58, 180, 1) 0%,
    //   rgba(253, 29, 29, 1) 50%,
    //   rgba(252, 176, 69, 1) 100%
    // );
    // background: -webkit-linear-gradient(
    //   90deg,
    //   rgba(131, 58, 180, 1) 0%,
    //   rgba(253, 29, 29, 1) 50%,
    //   rgba(252, 176, 69, 1) 100%
    // );

    // background: linear-gradient(90deg, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833ab4",endColorstr="#fcb045",GradientType=1);

    // background-image: linear-gradient(to right top, #051937, #384164, #6b6e95, #a09ec9, #d9d1ff);
    // background: #BAD7FF;
  }

  @media screen and (max-width: 1200px) {
    margin: 0px 5%;
  }

  @media only screen and (min-width: 608px) {
    justify-content: center;
    align-items: center;
  }

  .homescreen {
    width: 100%;
    margin: 10px 0px;
    border-radius: 4px;
    background-color: aliceblue;
    padding: 10px;
    height: 40px;
  }

  .customers {
    width: 50%;
    margin: 20px 0px;

    @media only screen and (min-width: 768px) {
      width: 50%;
      /* margin: 20px 0px; */
      /* float: right; */
      // position: absolute;
      // right: 0px;
      // top: 20%;
    }

    @media only screen and (min-width: 769px) {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      width: 85%;
    }
  }

  .customers2 {
    width: 50%;
    margin: 20px 0px;
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      width: 40%;
      /* margin: 20px 0px; */
      /* float: right; */
      // position: absolute;
      // right: 0px;
      // top: 20%;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .brand-logo {
    width: 80px;
    box-shadow:
      1px 0 8px 0 rgba(0, 0, 0, 0.05),
      8px 8px 18px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #b7b7b7;
    text-align: center;
    border-radius: 2px;
    margin: 16px 0px;
    padding: 4px;
    
    img {
      width: 80px;
    }
  }

  .footer-logo {
    width: 180px;
    // box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.05), 8px 8px 18px 0 rgba(0, 0, 0, 0.05);
    // border: 1px solid #b7b7b7;
    padding: 4px;
    border-radius: 2px;
    margin: 20px 0px 30px;
  }

  .auth-form-wrapper {
    padding: 15px 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(104, 97, 97, 0.5);
    // display: flex;
    // align-items: center;
    border-radius: 4px;
    background-color: white;


    width: 40%;

    @media only screen and (max-width: 768px) {

      width: 95%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }


    .login-form-container {
      flex-basis: 50%;
      border: none !important;
      box-shadow: none !important;
      padding: 0 30px;

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }

    .center-line {
      border-left: 2px solid #d9d9d9;
      height: 180px;
    }

    .center-line {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 0;
      // min-width: 275px;
      flex-direction: column;
    }
    .form-title {
      h4 {
        color: #071822;
        font-weight: 600;
        // size: 13px;
        margin: 10px 0px;
        font-size: 20px;

        @media only screen and (max-width: 1400px) {
          line-height: 30px;
        }
      }
    }
    .ant-form {
      // height: 100%;
      .ant-form-item {
        margin: 30px 0px;
        .ant-form-item-label {
          margin: 0 0 8px 0;
          line-height: 0;
          padding: 0;
        }
      }
      .ant-btn-primary {
        margin-top: 10px;
      }
    }
    .ant-form-explain {
      position: absolute;
      font-size: 12px;
      margin-left: 2px;
    }
    .ant-btn-primary {
      // width: 100%;
      background-color: #0c66e4;
      height: 35px;
      border-radius: 4px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        background-color: #0c66e4;
        // min-width: 275px;
      }
    }
    .ant-btn-secondary {
      width: 100%;
    }
  }

  .otp-input-container p {
    margin: 20px 0 -10px 0;
  }

  .otp-input-container .otp-title {
    @media only screen and (max-width: 768px) {
      text-align: left;
    }
  }

  .otp-input-container .resend-otp-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -10px;
    color: #0c66e4;
    margin-bottom: 10px;
  }

  .ant-form-vertical .ant-form-item .ant-form-item-control {
    min-width: 100%;
  }

  .resend-otp-link {
    margin: auto;
  }

  .footer {
    position: absolute;
    bottom: 20px;
    display: flex;
    width: 50%;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.otp-input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

input {
  border: 2px solid #d9d9d9;
  border-radius: 2px;
  // padding: 30px;
}

input:focus {
  border-color: #fff !important;
}

.otp-container input[type="text"]:focus {
  border-color: #fff !important;
}
