.sidemenu {
  .intro {
    padding: 5px 16px;

    .logo-wrapper {
      // border-bottom: 1px solid #eaeaea;

      .logo-welcome {
        width: 200px;

        &.open {
          width: 100px !important;
        }

        &.close {
          width: 45px !important;
        }

        @media only screen and (max-width: 500px) {
          margin-right: 0px;

          width: 250px;
        }

        cursor: pointer;
      }
    }

    small {
      margin: 0px 10px;
    }

    .locations {
      margin: 10px 0px;

      .ant-tag {
        margin-right: 4px;

        margin-bottom: 4px;
      }
    }
  }

  .menu-item {
    .menu-collapsed {
      display: flex;

      i {
        width: 20px;

        color: #5a5a5a;
      }
    }
  }

  .sidebar-footer {
    position: fixed;

    bottom: 0px;

    width: 250px;

    background-color: white;

    border-top: 1px solid #e3e3e3;

    &.open {
      width: 256 !important;
    }

    &.close {
      width: 80px !important;

      img.footer-logo {
        display: none;
      }
    }

    img.footer-logo {
      width: 120px;

      padding: 10px;
    }
  }
}
