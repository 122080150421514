.task-form {
    .checklist {
        margin: 10px 0px;
        .step {
            border-radius: 4px;
            padding: 10px;
            margin: 10px 0px;
            background-color: aliceblue;
            border: 1px solid #ceebff;
        }
    }

    .form-footer {
        display: flex;
        justify-content: flex-end;
    }
    .configure-button {
        // float: right;
        padding: 10px;
    }

    .step-form-container {
        display: flex;
        justify-content: space-between;
        
    }
}
