/**
**/

.slots {
    .calendar-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 10px 0px;

        .left {
            width: 100%;

            .top-bar {
                display: flex;
                @media only screen and (max-width: 500px) {
                    flex-direction: column-reverse;
                }

                .selected-package-container {
                    width: 100%;
                    margin-right: 10px;
                    @media only screen and (max-width: 500px) {
                        margin-right:0px
                    }
                }

                .notice-card {
                   flex-basis: 50%;
                   margin:0px 0px;
                }
            }
           


            // max-width: 420px;
            // flex-basis: 40%;
        }

        .time-slot-container {
            padding: 10px;
            @media only screen and (max-width: 500px) {
                padding:0px;
            }
            // flex-basis: 60%;
            button {
                margin: 20px 10px;
            }

            .calendar-container {
                display: flex;
                @media only screen and (max-width: 500px) {
                    flex-direction: column;
                }
                .calendar {
                    flex-basis: 50%;
                }

                .calendar-slots {
                    padding: 0px 10px;
                    flex-basis: 50%;
                    margin-top: 15px;
                }
            }
        }

        .loading-text {
            padding: 0px 12px;
        }

        .time-slots {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .item {
                width: 90px;
                cursor: pointer;
                border: 1px solid #d9d8d8;
                padding: 12px;
                margin: 4px 8px;
                border-radius: 4px;
                text-align: center;

                &:hover {
                    border-color: #a2a2a2;
                }
                &.active {
                    color: white;
                    background-color: #1a90ff;
                    font-weight: bold;
                    border-color: #1a90ff;

                    &:hover {
                        border-color: #1a90ff;
                        // border: none;
                    }
                    // border-color: #8cbeee;
                }

                &.booked {
                    background-color: #e7e7e7;
                }
            }
        }
    }
}
