.camera-modal {
    &.ant-modal {
        top: 0px !important;
        max-width: unset;
        margin: unset;
    }
    .ant-modal-body {
        // height: calc(100vh - 110px);
        background-color: #191919;

        padding: 0px;
    }

    .camera-top-area {
        display: flex;

        .camera-window {
            flex-basis: 90%;
            .react-html5-camera-photo {
                video {
                    // width: 100% !important;
                }
            }
        }
        .camera-action-bar {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap:10px
        }
    }

    .preview {
        display: flex;
        .image-gallery {
            width: 200px;
            padding: 0px;
            border: 1px solid #484848;
            margin: 0px 4px;
            position: relative;
            .image-preview {
                width: 200px;
                img {
                    width: 100%;
                    margin: 10px 0px;
                }
            }
            .delete-button {
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }
    }
}

.web-camera {
    .web-camera-container {
        .web-camera-box {
            width: 100%;

            span.anticon {
                font-size: 20px;
            }

            .image-container {
                img {
                    max-width: 150px;
                }
            }
        }
        .actions {
            .preview {
                display: flex;
            }

            .camera-trigger {
                margin-top: 10px;
            }
            button {
                // width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
