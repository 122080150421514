.finger-print-search {
    position: relative;

    p {
        @media screen and (min-width: 600px) {
            width: 70%;
        }
    }

    .finger-print-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .finger-print-box {
            width: 100%;

            span.anticon {
                font-size: 20px;
                position: absolute;
                right: 10px;
                top: 10px;
            }

            .image-container {
                // height: 150px;
                img {
                    max-width: 150px;
                    // height: 150px;

                    // width: 100%;
                }
            }
        }
        .actions {
            margin: 10px 0px;
            display: flex;
            // flex-direction: column;
            // margin: 0px 10px;
            align-items: flex-end;
            button {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
