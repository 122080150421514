.candidate-list {
    .page-actions {
        display: flex;
    }

    .page-search {
        display: flex;
        align-items: center;

        .search-input {
            height: 32px;
            border: 1px solid #b9b8b8;
            border-radius: 2px;
        }
    }

    .ant-card-body {
        padding: 0px !important;
    }
}
