.table-wrapper {
    .page-header {
        justify-content: flex-end;

        margin: 5px;
    }
}

table {
    width: 100%;

    &.styled-table {
        border-collapse: collapse;
        // margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        overflow: scroll;

        thead tr {
            background-color: #009879;
            color: #ffffff;
            text-align: left;
            position: sticky;
            top: 0;
            z-index: 99;
            position: -webkit-sticky;
        }

        th,
        td {
            padding: 6px;
            font-size: 10px;
        }

        tbody tr {
            // height: 60px;

            cursor: pointer;
            border-bottom: 1px solid #dddddd;
            &:hover {
                background-color: #f1faff;
            }
            &:nth-of-type(even) {
                background-color: #f3f3f3;
            }
            &:last-of-type {
                border-bottom: 2px solid #009879;
            }
            &.active-row {
                font-weight: bold;
                color: #009879;
            }
            td {
                &.quantity {
                    width: 100px;
                }
            }
        }

        &.cart {
            thead tr {
                background-color: #003d98;
            }
            th,
            td {
                padding: 8px 12px;
            }
        }
    }
}
