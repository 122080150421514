.portlet-table {
    padding: 0px !important;

    // overflow-y: scroll;
    display: flex;

    .table-data {
        overflow-y: scroll;
        padding: 0px;
    }

    .actions-table {
        th,
        td {
            white-space: nowrap;
            padding: 6px;
            // font-size: 10px;
        }
    }

    table.table-body {
        width: 100%;

        border-collapse: collapse;
        // margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        overflow: scroll;

        thead tr {
            background-color: #fafafa;
            // background-color: #009879;
            // color: #ffffff;
            text-align: left;
            position: sticky;
            top: 0;
            z-index: 99;
            position: -webkit-sticky;
        }

        th,
        td {
            white-space: nowrap;
            padding: 6px;
            // font-size: 10px;
        }

        th {
            padding: 9px 14px;
            border-bottom: 1px solid #e0e0e0;
        }

        tbody tr {
            // height: 60px;

            cursor: pointer;
            border-bottom: 1px solid #dddddd;
            &:hover {
                background-color: #f1faff;
            }
            &:nth-of-type(even) {
                background-color: #f3f3f3;
            }
            &:last-of-type {
                border-bottom: 2px solid #009879;
            }
            &.active-row {
                font-weight: bold;
                color: #009879;
            }
            td {
                &.quantity {
                    width: 100px;
                }
            }
        }

        &.cart {
            thead tr {
                background-color: #003d98;
            }
            th,
            td {
                padding: 8px 12px;
            }
        }
    }
}
