.image-box {
    // padding: 10px;

    .ant-image {
        min-height: 115px;
    }

    img {
        min-height: 115px;

        // margin: 0px 10px;
        width: 150px;
        border: 1px solid #c5c5c5;
        padding: 4px;
        border-radius: 4px;
    }
}
