.fingerprint-protected {
    margin: 10px 0px;
    
    position: relative;
    .override-button {
        position: absolute;
        right: 0px;
        bottom: 30px;
    }
}
