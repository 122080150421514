.content{
    width: 100%;
    
      .main {
        padding-left: 80px;
        padding-top:30px ;
        img{
          padding-bottom: 24px;
          padding-top: 30px;
          width: 115px;
        }
  
      }
      .ant-input-number {
        box-sizing: border-box;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        width: 100%;
        min-width: 0;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        transition: all 0.3s;
        display: inline-block;
        width: 180px;
        margin: 0;
        padding: 0;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
    }
    
  }
  @media only screen and (max-width: 600px) {
    .content{
      width: 100%;
      .main {
        padding-left: 40px;
        padding-top:30px ;
  
    }
  
    }
      
   
  
  }
  