.task-list {
    .list-header {
        display: flex;
        justify-content: space-between;
    }

    .skeleton-wrapper {
        width: 100%;
    }

    .ant-alert {
        margin-bottom: 10px;
    }
}
