.form-creator {
    .ant-input-number {
        width: 100%;
    }

    .form-item-element {
        position: relative;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
        margin-bottom: 3px;

        .field-customizer {
            .actions {
                // display: none;
                float: right;
                // position: absolute;
                // right: 0px;
            }
        }
    }
}
