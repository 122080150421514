.skeleton-card {
    background-color: #ffffff;
    border-radius: 6px;
    // box-shadow: 1px 0 8px 0 rgb(0 0 0 / 5%), 8px 8px 18px 0 rgb(0 0 0 / 5%);
    margin: 20px auto;
    /* max-width: 500px; */
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .skeleton {
    background-color: #f2f2f2;
    border-radius: 5px;
    height: 20px;
    margin: 20px 0;
    width: 100%;
    animation: loading 1s ease-in-out infinite;
    transform-origin: 0% 50%;
  }

  .skeleton + .skeleton {
    animation-delay: 0.5s;
  }

  .skeleton + .skeleton + .skeleton {
    animation-delay: 0.3s;
  }

  .skeleton + .skeleton + .skeleton + .skeleton {
    animation-delay: 0.6s;
  }

  .skeleton + .skeleton + .skeleton + .skeleton + .skeleton {
    animation-delay: 0.5s;
  }

  @keyframes loading {
    0% {
      transform: translateX(-100%) skewX(-30deg);
    }
    100% {
      transform: translateX(200%) skewX(30deg);
    }
  }

  .loading-animation-text {
    font-family: sans-serif;
    margin: 10px;
    color: #838383;
    font-size: 16px;
    font-weight: 100;
  }