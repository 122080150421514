.field-selector {
    flex-basis: 30%;
    margin-top: 0px;
    margin-left: 8px;

    .selector-box {
        display: flex;
        flex-wrap: wrap;

        .field-element {
            width: 120px;
            border: 1px solid #e1e1e1;
            border-radius: 4px;
            padding: 16px;
            margin: 10px;
            cursor: pointer;
        }
    }

    .field-element {
        button {
            float: right;
        }
    }
}
