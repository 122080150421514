.user-name{
  padding: 0px 10px;
}

.generic-header {
  width: 100%;
  // max-width: 500px;
  background: white;
  top: 0px;
  // z-index: 500;
  height: 40px;
  display: flex;
  // padding: 0 8px;
  @media only screen and (max-width: 480px) {
    height: 22px;
  }
 
  .main-header {
    // display: grid;
    // grid-template-rows: 0.5fr 1fr;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancel-icon{
      // margin-top: 10px;
    }

    .user-avatar {
      border-radius: 40px;
      height: 50px;
      width: 50px;
      /* box-shadow: 0px 0px 5px 1px grey; */
      /* padding: 3px; */
      border: 1px solid #1a90ff;
    }

    .back-icon {
      padding: 5px 0;
      text-align: left;
      box-sizing: border-box;
      cursor: pointer;
      span {
        font-family: "Roboto Condensed";
        margin-left: 1px;
        color: #463737;
      }
      .anticon {
        margin-left: -12px;
      }
    }

    .heading-text {
      display: grid;
      grid-template-columns: 1fr 0.5fr;

      .generic-heading {
        padding: 4px 16px;

        h4 {
          height: 28px;
          line-height: 1.56;
          font-weight: bold;
          margin: 0px;
          font-size: 18px;
        }
      }

      &.no-right-text {
        grid-template-columns: 1fr;
      }

      .right-text {
        padding: 10px 16px;
      }
    }
  }

  .custom-header {
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 0px 16px;
    align-items: center;

    .text {
      font-size: 18px;
      font-weight: 600;
      color: #3c415e;
    }

    .cancel-icon {
      opacity: 0.7;
      font-size: 18px;
      text-align: right;
      color: #949292;


    }
  }
}
