.process-dashboard {
    &.card {
        .process-title {
            margin: 10px 0px;
        }

        .process-description {
            margin-bottom: 0px;
        }

        .process-timeline {
            margin: 15px 0px;

            .ant-timeline-item {
                padding-bottom: 0px;
            }

            .task-form-card {
                margin-bottom: 15px;
            }

            .ant-timeline-item-content {
                padding: 10px;
                border: 1px solid #e6e6e6;
                margin-bottom: 15px;
            }

            .timeline-content {
                display: flex;
                justify-content: space-between;

                .timeline-title {
                    margin-bottom: 0px;
                }

                .timeline-description {
                    margin-bottom: 0px;

                    small {
                        color: brown;
                    }
                }

                .actions {
                }
            }

            .ant-tag {
                text-transform: capitalize;
                margin-top: 10px;
            }

            i.pending-tag {
                color: brown;

                &.ongoing {
                    color: #d66716;
                }
            }
        }
    }
}
