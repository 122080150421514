.task-status {
    display: flex;
    justify-content: space-between;

    .actions{
        display: flex;
    }
    .timeline {
        margin-bottom: 5px;
    }
}
