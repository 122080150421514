.change-password {
  margin: 15px;
  min-height: 90vh;
  display: flex;
  // margin: 10px 3%;
  // align-items: center;

  @media only screen and (min-width: 768px) {
    justify-content: left;
    align-items: left;
  }

  .homescreen{
    width: 100%;
    margin: 10px 0px;
    border-radius: 4px;
    background-color: aliceblue;
    padding:10px;
    height: 40px;
  }
  .auth-form-wrapper {
    padding: 0px;
    width: 100%;
    box-sizing: border-box;
    @media only screen and (max-width: 768px) {
      padding: 0;
      // min-width: 275px;
    }

    .form-title {
      h3 {
        color: #071822;
        font-weight: 600;
        size: 18px;

        margin: 20px 0px;
        font-size: 32px;
      }
    }

    .ant-form {
      // height: 100%;
      .ant-form-item {
        margin: 30px 0px;
        .ant-form-item-label {
          margin: 0 0 8px 0;
          line-height: 020px;
          padding: 0px;
        }
      }
      .ant-btn-primary {
        margin-top: 0px;
      }
    }

    .ant-form-explain {
      position: absolute;
      font-size: 12px;
      margin-left: 2px;
    }

    .ant-btn-primary {
      width: 110px;
      @media only screen and (max-width: 768px) {
        width: 100px;
        // min-width: 275px;
      }
    }

    .ant-btn-secondary {
      width: 100px;
    }
  }

}

