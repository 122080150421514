.generic-list {
    // margin: 0px 15px;
    .table-header {
        display: flex;
        justify-content: space-between;

        .table-title {
            // flex-basis: 30%;
            // position: absolute;
            // top: 40px;
            // left:10px;
            h4 {
                text-transform: capitalize;
            }
        }

        .table-actions {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            button {
                // margin: 0px 4px;
            }
        }
    }

    table {
        th {
            &.ant-table-cell {
                white-space: nowrap;
            }
        }
    }
}
