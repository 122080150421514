.table-settings {
}

.table-settings-modal {


    .content {
        min-height: 400px;

        display: flex;
        justify-content: space-between;

        .card {

            flex-grow: 1;
            margin: 0px 4px;
            box-shadow: none;
            border: none;

            .ant-list{
                height: 400px;

            }

            .list-item {
                display: flex;
            }
        }
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 0px;
    }
}
