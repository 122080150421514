

.spotlight-modal{


    .spotlight-menu-list{

        padding: 0px;

        .list-group{
            margin-bottom: 0px;
            

            .list-group-item{
                padding: 12px 16px;
                
            }
    
        }

    }

    .skeleton-effect{
        margin: 0px 10px;
    }


    .ant-modal-content {
        background: transparent;
        .ant-modal-body {
            border-radius: 4px;
            padding: 0px;
            padding-top: 0px !important;
            background: transparent;
            .input-group {
                height: 50px;
                .input-group-prepend {
                    .input-group-text {
                        border: none;
                        background: white;
                    }
                }
                input.form-control {
                    border-radius: 4px;
                    
                    outline: none;
                    border: none;
                    border-left: none;
                    width: 100%;
                    height: 50px;
                    padding: 20px;
                }
            }
        }
        .card {
            margin-top: 4px;
            background-color: white;
            .list-group {
                list-style-type: none;
                // margin-left: -40px;
                .list-group-item {
                    // padding: 3px 0px;
                    cursor: pointer;
                    &:hover {
                        background: whitesmoke;
                    }
                    &:focus {
                        background: whitesmoke;
                    }
                    &.hovered {
                        background: whitesmoke;
                    }
                    &:active {
                        background: whitesmoke;
                    }


                }
            }
        }
    }

}
