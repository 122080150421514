.card {
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1rem;
}

.card-title {
  margin: 0 0 1rem;
}
